import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	undo: {
		id: 'fabric.editor.undo',
		defaultMessage: 'Undo',
		description: 'Undo the previously performed action.',
	},
	redo: {
		id: 'fabric.editor.redo',
		defaultMessage: 'Redo',
		description: 'Redo the previously undone action.',
	},
});
